.content h1 {
    font-size: 5.5rem;

    @media (max-width:770px) {
        font-size: 2.5rem
    }
}


.content p {
    font-size: 1.5rem;
    margin-bottom: 0;

    @media (max-width:770px) {
        font-size: 1rem
    }
}


.card-responsive {
    @media (max-width:430px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
}

.card-responsive,
.heading-number {
    @media (max-width:1030px) {
        margin-left: 0px !important;
        padding-left: 0px !important;
    }
}

// our-vision Section
.our-vision {
    font-size: 50px !important;
}

// Our Project Section
.headingForInterior {
    font-size: 3.5rem;

    @media (max-width:1030px) {
        font-size: 1.5rem
    }
}

// Choose Section

.over,
h2 {
    font-size: 4rem;
    z-index: 2;

    @media (max-width:770px) {
        font-size: 2rem !important;
    }

}